<template>
  <div style="margin-bottom: 120px;">
    <b-row>
      <b-col lg="8">
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('nlt-smartbus?') }}</p>
        <p>{{ $t('nlt-smartbus?-content') }}</p>
        <div class="text-center my-4">
          <img src="/assets/image/blog/manual-1.png" width="100%">
        </div>
        <p>{{ $t('nlt-smartbus?-content2') }}</p>
        <p class="mb-40">{{ $t('nlt-smartbus?-content') }}</p>
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('all-feature') }}</p>
        <div>
          <p>{{ $t('all-feature-content') }}</p>
        </div>
        <div>
          <p>{{ $t("all-feature-content2") }}</p>
        </div>
        <div class="text-center my-4">
          <img src="/assets/image/blog/manual-5.png" width="100%">
        </div>
        <p>{{ $t("all-feature-content3") }}</p>
        <!-- <div class="text-center my-4">
          <img src="/assets/image/guide/guide_5.jpg" height="512px">
        </div> -->
        <p>{{ $t("all-feature-content4") }}</p>
        <!-- <p>{{ $t("all-feature-content5") }}</p> -->
        <!-- <div class="text-center mb-40 mt-4">
          <img src="/assets/image/guide/guide_13.jpg" height="512px">
        </div> -->
        <p class="text-green ma-28-semi mb-4 w-100">{{ $t('benefit-of-app') }}</p>
        <p>{{ $t('benefit-of-app-content') }}</p>
        <p>{{ $t('benefit-of-app-content2') }}</p>
        <p>{{ $t('benefit-of-app-content3') }}</p>
      </b-col>
      <b-col lg="4">
        <div>
          <RightMenu/>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RightMenu from '@/layout/RightMenu'
export default {
    components: {
        RightMenu
    },
    methods: {
        handleClick(route){
            this.$router.push(`/guideline/${route}`)
        }
    }
};
</script>

<style scoped>
.separator {
  background: #e6e8ec;
  height: 1px;
}
.menu-content {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Menu */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
}
.menu-title {
  color: var(--brand-color, #c71313);
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.right-menu {
  border-radius: 24px;
  border: 1px solid var(--website-gray, #e6e8ec);
  background: var(--website-white, #fff);
  padding: 32px;
}
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: var(--website-brand-color, #c71313);
  /* Website & tablet/Header 1 */
  font-size: 48px;
  font-weight: 400;
  line-height: 60px; /* 125% */
  margin-bottom: 40px !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
}
.content-card p {
  height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
.mb-40{
  margin-bottom: 40px;
}
</style>
